import * as React from "react";
import {Accordion} from 'react-bootstrap';


const CtaCard = (props) => {

    const {title, subtitle, description, dispensers, img} = props;

    return (
        <div className="cta-card">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-7">
                    <h2 className="heading-2 mb-4 mb-lg-5">{title}</h2>
                    {subtitle}
                    {description}
                    <div className="d-flex flex-column mt-5">
                        <Accordion>
                            {
                                dispensers.map((dispenser,i)=>(
                                    <Accordion.Item eventKey={i} key={i}>
                                        <Accordion.Header>
                                            <h3>{dispenser.title}</h3>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <h4 className="fs-14 text-dark-grey text-regular mt-n1">{dispenser.subtitle}</h4>
                                            <p className="fs-14 text-grey text-regular mt-1">{dispenser.description}</p>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))
                            }
                        </Accordion>
                    </div>
                </div>
                <div className="col-12 col-md-6 col-lg-5">
                    <img src={img} className="cta-img" alt="Rosmino Ivess - Dispenser" />
                </div>
            </div>
            
        </div>
    )
}

export default CtaCard;