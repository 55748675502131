import React from 'react';
import {Carousel} from 'react-bootstrap';


const LandingCarousel = () => {
  return (
    <div className="landing-carousel">
        <Carousel>
            <Carousel.Item>
                <img src="/carousel-img-1.png" className="img-landing" alt="Promo Rosmino IVESS" />
            </Carousel.Item>
            <Carousel.Item>
                <img src="/carousel-img-2.png" className="img-landing" alt="Promo Rosmino IVESS" />
            </Carousel.Item>
            <Carousel.Item>
                <img src="/carousel-img-3.png" className="img-landing" alt="Promo Rosmino IVESS" />
            </Carousel.Item>
        </Carousel>
    </div>
  )
}


export default LandingCarousel;