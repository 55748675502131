import React, {useState} from "react";
import axios from 'axios';
import { navigate } from "gatsby";



const LandingForm = (props) => {
    const isBrowser = typeof window !== "undefined"
    const {title, subtitle} = props;

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [location, setLocation] = useState("");
    const [comment, setComment] = useState("");
    const [formErrors, setFormErrors] = useState("");

    const formValues = {
        utm: isBrowser && window.sessionStorage.getItem('utm'),
        name:name,
        email:email,
        phone:phone,
        location:location,
        comment:comment
    };

    const enabled = name && email && phone && location && comment;

    const handleSubmit = () => {
        axios.post(`https://api-landing.rosmino.com.ar/wcp/send-email`, formValues)
        .then((res) => {
            navigate("/gracias");
        })
        .catch((err) => {
            setFormErrors(err.response.data);
        })
    }

    return (
        <div className="landing-form">  
            <h3 className="fs-20 text-center text-bold mb-2">{title}</h3>
            <p className="text-regular text-center text-grey mb-4">{subtitle}</p>
            <form action="">
                <div className="form-group">
                    <input 
                        id="name"
                        value={name}
                        className={formErrors.name ? "form-control invalid" : "form-control"}
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nombre"
                    />
                    {
                        formErrors.name &&
                        <p className="text-invalid mt-2 mb-3">{formErrors.name[0]}</p>
                    }
                </div>
                <div className="form-group">
                    <input 
                        id="email"
                        value={email}
                        className={formErrors.email ? "form-control invalid" : "form-control"}
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                    />
                    {
                        formErrors.email &&
                        <p className="text-invalid mt-2 mb-3">{formErrors.email[0]}</p>
                    }
                </div>
                <div className="form-group">
                    <input 
                        id="phone"
                        value={phone}
                        className={formErrors.phone ? "form-control invalid" : "form-control"}
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Celular"
                    />
                    {
                        formErrors.phone &&
                        <p className="text-invalid mt-2 mb-3">{formErrors.phone[0]}</p>
                    }
                </div>
                <div className="form-group">
                    <input 
                        id="location"
                        value={location}
                        className={formErrors.location ? "form-control invalid" : "form-control"}
                        type="text"
                        onChange={(e) => setLocation(e.target.value)}
                        placeholder="Barrio/Localidad"
                    />
                    {
                        formErrors.location &&
                        <p className="text-invalid mt-2 mb-3">{formErrors.location[0]}</p>
                    }
                </div>
                <div className="form-group">
                    <textarea 
                        id="comment"
                        value={comment}
                        className={formErrors.comment ? "form-control invalid" : "form-control"}
                        rows="4"
                        onChange={(e) => setComment(e.target.value)}
                        placeholder="Consulta"
                    />
                    {
                        formErrors.comment &&
                        <p className="text-invalid mt-2 mb-3">{formErrors.comment[0]}</p>
                    }
                </div>
                <div className="d-flex mt-4">
                    <button
                        type="button"
                        className={enabled ? "btn-cd-primary" : "btn-cd-primary disabled"}
                        onClick={enabled && (() => handleSubmit())}
                    >
                        Enviar
                    </button> 
                </div>
            </form>
        </div>
    )
}

export default LandingForm;