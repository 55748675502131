import * as React from "react";


const ProductLandingCard = (props) => {

    const {img, imgDescription, title} = props;

    return (
        <div className="product-landing-card">
            <div className="img-container">
                <img 
                    src={img}
                    className="h-100" 
                    alt={imgDescription}
                />
            </div>
            <p className="text-dark-grey mt-2">{title}</p>
        </div>
    )
}

export default ProductLandingCard;