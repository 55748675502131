import * as React from "react";


const FeaturesCard = (props) => {

    const {icon, title, subtitle, color} = props;

    return (
        <div className="features-card">
            <div className="d-flex align-items-center">
                <div className="me-3">
                    <div className={`icon-container ${color}`}>
                    <img src={icon} alt="icon" />
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <p className="text-bold">{title}</p>
                    <p className="text-regular text-dark-grey mt-2">{subtitle}</p>
                </div>
            </div>
        </div>
    )
}

export default FeaturesCard;