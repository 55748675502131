import * as React from "react";
import "../assets/app.scss";
import Header from "../components/Header";
import LandingOnboardingButton from "../components/Buttons/LandingOnboardingButton";
import LandingCarousel from "../components/LandingCarousel";
import { onboardingData } from "../data/onboarding.js";
import {
    landingHeroData,
    landingOurProductsData,
    landingWhyChooseUsData,
    landingCtaData,
    landingFormData,
    landingCoverageAreasData,
    landingFaqData,
} from "../data/landing.js";
import { seoLanding } from "../data/seo";
import FeaturesCard from "../components/Cards/FeaturesCard";
import ProductLandingCard from "../components/Cards/ProductLandingCard";
import CtaCard from "../components/Cards/CtaCard";
import FaqCard from "../components/Cards/FaqCard";
import LandingForm from "../components/LandingForm";

import Footer from "../components/Footer";
import * as queryString from "query-string";
import { Accordion } from "react-bootstrap";
import Seo from "../components/seo";
import { Helmet } from "react-helmet";

const Landing = ({ location }) => {
    const isBrowser = typeof window !== "undefined";
    const { utm_source, ri_newsletter, utm_term } = queryString.parse(
        location.search
    );
    if (isBrowser) {
        sessionStorage.setItem("utm", utm_source);
        sessionStorage.setItem("utm_term", utm_term);
        if (ri_newsletter) {
            sessionStorage.setItem("ri_newsletter", ri_newsletter);
        } else {
            sessionStorage.removeItem("ri_newsletter");
        }
    }

    const { buttons } = onboardingData;
    const { categories, products } = landingOurProductsData;
    const { features } = landingWhyChooseUsData;

    return (
        <div className="landing">
            <Seo
                title={seoLanding.title}
                description={seoLanding.description}
            />

            <Helmet
                bodyAttributes={{
                    class: "bk-white",
                }}
            />

            <div className="container-custom border-bottom">
                <div className="row">
                    <div className="col-12 p-0">
                        <Header showMenu={true} />
                    </div>
                </div>
            </div>

            <div className="hero-landing" id="hero">
                <div className="container-fluid position-relative">
                    <img src="/forma-1.svg" className="forma-1" alt="forma" />
                    <div className="container-custom">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <h2 className="heading-1 mt-5">
                                    {landingHeroData.title}
                                </h2>
                                <h1 className="fs-22 text-cd-primary text-bold mt-3">
                                    {landingHeroData.subtitle}
                                </h1>
                                <p className="text-black text-center text-lg-start text-regular my-5 my-lg-4">
                                    Elegí la opción que más te convenga y{" "}
                                    <b>¡date de alta!</b>
                                </p>
                                <div className="row">
                                    {buttons.map((button, i) => (
                                        <div
                                            className="col-12 col-lg-6"
                                            key={i}
                                        >
                                            <LandingOnboardingButton
                                                link={button.link}
                                                img={button.whiteImg}
                                                title={button.title}
                                                subtitle={button.subtitle}
                                                description={button.description}
                                            />
                                        </div>
                                    ))}
                                </div>
                                {/* <p className="text-dark-grey text-center text-lg-start text-regular my-5 my-lg-4">
                  o contactate <a href="#contact" className="text-cd-primary text-medium">completando el formulario</a>
                </p> */}
                            </div>
                            <div className="col-12 col-lg-6">
                                <LandingCarousel />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="our-products" id="products">
                <div className="container-custom">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h2 className="heading-2 mb-4 mb-lg-2">
                                Conocé nuestros productos
                            </h2>
                            <div className="d-flex flex-wrap justify-content-center mb-4 mb-lg-5">
                                {categories.map((category, i) => (
                                    <h3
                                        key={i}
                                        className="fs-22 text-cd-primary text-medium mx-1"
                                    >
                                        {category}
                                    </h3>
                                ))}
                            </div>
                            <div className="row">
                                {products.map((product, i) => (
                                    <div
                                        className="col-6 col-lg-3 my-3"
                                        key={i}
                                    >
                                        <ProductLandingCard
                                            img={product.img}
                                            imgDescription={
                                                product.imgDescription
                                            }
                                            title={product.title}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="why-choose-us">
                <img src="/forma-2.svg" className="forma-2" alt="icon" />
                <div className="container-custom">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="heading-2 text-center mb-5">
                                {landingWhyChooseUsData.title}
                            </h2>
                            <div className="row justify-content-center">
                                {features.map((feature, i) => (
                                    <div
                                        className="col-12 col-lg-5 p-4 p-lg-5"
                                        key={i}
                                    >
                                        <FeaturesCard
                                            icon={feature.icon}
                                            title={feature.title}
                                            subtitle={feature.subtitle}
                                            color={feature.color}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="dispenser-cta-card" id="dispenser">
                <img src="/forma-3.svg" className="forma-3" alt="icon" />
                <div className="container-custom">
                    <div className="row">
                        <div className="col-12">
                            <CtaCard
                                title={landingCtaData.title}
                                subtitle={landingCtaData.subtitle}
                                description={landingCtaData.description}
                                dispensers={landingCtaData.dispensers}
                                img={landingCtaData.img}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="form-contact" id="contact">
                <div className="container-custom">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-12 col-md-8 col-lg-6">
                            <img
                                src="/bk-form.svg"
                                className="bk-form"
                                alt="icon"
                            />
                            <h2 className="heading-2 text-center mb-2 mb-lg-3">
                                {landingFormData.title}
                            </h2>
                            <p className="fs-22 text-center text-cd-primary text-bold">
                                {landingFormData.subtitle}
                            </p>
                            <LandingForm
                                title={landingFormData.form.title}
                                subtitle={landingFormData.form.subtitle}
                                fields={landingFormData.form.fields}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="coverage-areas bk-extra-light-grey">
                <div className="container-custom">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="heading-2 text-center text-lg-start mb-5">
                                {landingCoverageAreasData.title}
                            </h2>
                        </div>
                        <div className="col-12 col-lg-5">
                            <div className="badge">
                                <img
                                    src={landingCoverageAreasData.badge.icon}
                                    alt="icon"
                                />
                                {landingCoverageAreasData.badge.text}
                            </div>
                            <div className="row my-5">
                                {landingCoverageAreasData.mainCities.map(
                                    (city, i) => (
                                        <div className="col-6 my-1" key={i}>
                                            <p className="text-medium text-dark-grey">
                                                {city}
                                            </p>
                                        </div>
                                    )
                                )}
                                <div className="col-12 mt-3">
                                    <p className="text-dark-grey text-regular">
                                        ¡y también barrios cerrados!
                                    </p>
                                </div>
                            </div>
                            <div className="contact">
                                <p className="fs-20 text-grey text-center text-bold mb-3">
                                    {landingCoverageAreasData.contact.title}
                                </p>
                                {landingCoverageAreasData.contact.subtitle}
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 offset-lg-1 mt-5 mt-lg-0">
                            <div className="row">
                                {landingCoverageAreasData.cities.map(
                                    (city, i) => (
                                        <div className="col-4" key={i}>
                                            <p className="city text-grey text-medium">
                                                {city}
                                            </p>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="faq" id="faq">
                <div className="container-custom">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="heading-2 mb-4 mb-lg-5 text-center">
                                {landingFaqData.title}
                            </h2>
                        </div>
                        <div className="col-12">
                            <Accordion>
                                <div className="row">
                                    {landingFaqData.faqs.map((faq, i) => (
                                        <div
                                            className="col-12 col-lg-6"
                                            key={i}
                                        >
                                            <FaqCard
                                                faqId={i}
                                                question={faq.question}
                                                answer={faq.answer}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bk-cd-primary">
                <div className="container-custom">
                    <div className="row">
                        <div className="col-12">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Landing;
