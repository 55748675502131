

export const onboardingData = {
    title: "Sumate a la familia IVESS",
    subtitle: "Hacete cliente y empezá a llevar agua pura y de calidad para vos y tu familia.",
    buttons:[
        {
            link: "/alta-con-asesor",
            img: "/manual-registration.svg",
            whiteImg: "/manual-registration-white.svg",
            title: "Alta con asesor",
            subtitle: "Un agente se estará comunicando con vos a la brevedad.",
            description:"Lun - vie 8 a 18  ·  Sáb 8 a 15",
            value: "SELLER_ORDER"
        },
        {
            link: "/alta-automatica",
            img: "/auto-registration.svg",
            whiteImg: "/auto-registration-white.svg",
            title: "Alta automática",
            subtitle: "Sumate a nuestra familia en sólo en 2 minutos y 6 simples pasos",
            value: "AUTOMATIC_ORDER"
        },
    ]
}   




