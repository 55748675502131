import * as React from "react";
import {Accordion} from 'react-bootstrap';


const FaqCard = (props) => {

    const {faqId, question, answer} = props;

    return (
        <div className="faq-card my-2">
            <Accordion.Item eventKey={faqId}>
                <Accordion.Header>
                    <h3 className="fs-16 text-bold m-0">{question}</h3>
                </Accordion.Header>
                <Accordion.Body>
                    {answer}
                </Accordion.Body>
            </Accordion.Item>
        </div>
    )
}

export default FaqCard;