import * as React from "react";
import { Link } from "gatsby"

const LandingOnboardingButton = (props) => {

    const {link, img, title, subtitle, description} = props;

    return (
        <Link 
            className="landing-onboarding-button"
            to={link}
        >
            <div>
                <div className="button-img mb-3">
                    <img src={img} height="40" alt="icon" />
                </div>
            </div>
            <div className="d-flex flex-column">
                <h2 className="fs-20 text-black text-lg-center text-bold mb-0">{title}</h2>
                {description && <p className="fs-14 text-lg-center text-grey">{description}</p>}
                <p className="text-dark-grey text-lg-center mt-2">{subtitle}</p>
                <p className="signup-button text-medium text-lg-center text-cd-primary mt-3">Darme de alta <img src="./arrow.svg" className="mr-2" alt="icon"/></p>
            </div>
        </Link>
    )
}

export default LandingOnboardingButton;
