import React from "react";

export const landingHeroData = {
  title: "Sumate a la familia y dale IVESS a tu vida",
  subtitle: "Agua IVESS",
};

export const landingOurProductsData = {
  title: "Conocé nuestros productos",
  categories: [
    "Agua Mineral Ivess |",
    "Dispenser de Agua |",
    "Bidones de Agua Ivess |",
    "Soda Ivess",
  ],
  products: [
    {
      img: "/rosmino-ivess-bidon.png",
      imgDescription: "Rosmino Ivess - Bidon 20L",
      title: "Botellón 20L",
    },
    {
      img: "/rosmino-ivess-botellon.png",
      imgDescription: "Rosmino Ivess - Botellon retornable 12L",
      title: "Botellón retornable 12L",
    },
    {
      img: "/rosmino-ivess-soda.jpg",
      imgDescription: "Rosmino Ivess - Soda en Sifón retornable 1L",
      title: "Soda en sifón retornable 1L",
    },
    {
      img: "/rosmino-ivess-agua-saborizada.png",
      imgDescription: "Rosmino Ivess - Agua saborizada 0.5 y 1.5L",
      title: "Agua saborizada 0.5 y 1.5 lts",
    },
    {
      img: "/rosmino-ivess-botellon-descartable.png",
      imgDescription: "Rosmino Ivess - Botellon descartable 8L",
      title: "Botellón descartable 8L",
    },
    {
      img: "/rosmino-ivess-botellon-descartable-6.png",
      imgDescription: "Rosmino Ivess - Botellon descartable 6L",
      title: "Botellón descartable 6L",
    },
    {
      img: "/rosmino-ivess-soda-descartable.png",
      imgDescription: "Rosmino Ivess - Soda en sifón descartable",
      title: "Soda en sifón descartable",
    },
    {
      img: "/rosmino-ivess-agua.png",
      imgDescription: "Rosmino Ivess - Agua mineral con y sin gas 0.5L y 1L",
      title: "Agua mineral con y sin gas 0.5L y 1L",
    },
  ],
};

export const landingWhyChooseUsData = {
  title: "¿Por qué elegirnos?",
  features: [
    {
      icon: "/icon-quality.svg",
      title: "Calidad",
      subtitle: "Nomas de Excelencia Ivess, máxima pureza y calidad.",
      color: "bk-quality",
    },
    {
      icon: "/icon-money.svg",
      title: "Precio",
      subtitle:
        "Comparativamente los botellones resultan más económicos y prácticos.",
      color: "bk-money",
    },
    {
      icon: "/icon-home.svg",
      title: "Entrega a domicilio",
      subtitle: "Servicio de entrega puerta a puerta rápido y sin cargo.",
      color: "bk-home",
    },
    {
      icon: "/icon-recicling.svg",
      title: "Cuidado del ambiente",
      subtitle: "Con nuestros botellones retornables cuidamos el planeta.",
      color: "bk-recicling",
    },
  ],
};

export const landingCtaData = {
  title: "Servicio Frío-Calor",
  subtitle: (
    <p className="text-dark-grey text-regular">
      Con la compra mínima de un botellón por semana,<br></br>
      <span className="badge">OBTENÉ SIN CARGO EL DISPENSER</span>
      <br></br>y tomá agua de calidad a la temperatura deseada en forma
      instantánea.
    </p>
  ),
  description: (
    <p className="text-regular text-dark-grey mt-4">
      🔧 &nbsp; Mantenimiento y servicio técnico sin cargo
    </p>
  ),
  dispensers: [
    {
      title: "Medidas Dispenser de pie",
      subtitle: "130cm alto x 40cm ancho x 40cm largo",
      description: "(incluye botellón de 12L)",
    },
    {
      title: "Medidas Dispenser de mesada",
      subtitle: "80cm alto x 40cm ancho x 40cm largo",
      description: "(incluye botellón de 12L)",
    },
  ],
  img: "/cta-img.png",
};

export const landingFormData = {
  title: "Unite a la familia IVESS",
  subtitle: "Pedir agua Ivess a domicilio",
  form: {
    title: "¿Querés mejorar tu calidad de vida?",
    subtitle:
      "Completá el formulario y nos comunicaremos con vos a la brevedad.",
  },
};

export const landingCoverageAreasData = {
  title: "Nuestras áreas de cobertura",
  badge: {
    icon: "/icon-truck.svg",
    text: (
      <p className="text-cd-primary text-start text-regular">
        Entregamos tu pedido <br></br>
        <b>¡en menos de 24 horas!</b>
      </p>
    ),
  },
  mainCities: [
    "CABA",
    "Vicente López",
    "San Isidro",
    "San Fernando",
    "Tigre",
    "San Martín",
    "San Miguel",
    "Malvinas Argentinas",
    "Escobar",
    "Pilar",
  ],
  contact: {
    title: "¿Dudás si llegamos a tu domicilio?",
    subtitle: (
      <p className="fs-20 text-cd-primary text-center text-regular">
        Comunicate al{" "}
        <a
          href="tel:08005551900"
          className="text-cd-primary text-decoration-none"
          target="_blank"
          rel="noreferrer"
        >
          <b>0800-555-1900</b>
        </a>
        <br></br>o por Whatsapp al{" "}
        <a
          href="http://wa.link/breru7"
          className="text-cd-primary text-decoration-none"
          target="_blank"
          rel="noreferrer"
        >
          <b>11-3926-7357</b>
        </a>
      </p>
    ),
  },
  cities: [
    "Bancalari",
    "Barrio Sarmiento",
    "Barrio Libertador",
    "Beccar",
    "Bella Vista",
    "Benavidez",
    "Billinghurst",
    "Boulogne",
    "Campo de Mayo",
    "Carapachay",
    "Carupa",
    "Caseros",
    "Chilavert",
    "Ciudad Jardín",
    "Del Viso",
    "Derqui",
    "Don Torcuato",
    "El Palomar",
    "El Talar",
    "Escobar",
    "Fátima",
    "Florida",
    "Garín",
    "Haedo",
    "Hurlingham",
    "Ingeniero Maschwitz",
    "Jose C. Paz",
    "Jose León Suarez",
    "La Horqueta",
    "La Lonja",
    "La Lucila",
    "La Paloma",
    "Las Tunas",
    "Loma Hermosa",
    "Lopez Camelo",
    "Los Cardales",
    "Los Polvorines",
    "Los Troncos",
    "Malvinas Argentinas",
    "Manuel Alberti",
    "Manzanares",
    "Maquinista Savio",
    "Martín Coronado",
    "Martinez",
    "Matheu",
    "Munro",
    "Muñiz",
    "Nordelta",
    "Olivos",
    "Pablo Nogués",
    "Pablo Podestá",
    "Pacheco",
    "Pilar",
    "Ricardo Rojas",
    "Saenz Peña",
    "San Andrés",
    "San Fernando",
    "San Isidro",
    "San Martín",
    "San Miguel",
    "Santos Lugares",
    "Sourdeaux",
    "Tigre",
    "Tortuguitas",
    "Vicente López",
    "Victoria",
    "Villa Adelina",
    "Villa Astolfi",
    "Villa Ballester",
    "Villa Bonich",
    "Villa Bosch",
    "Villa Concepción",
    "Villa de Mayo",
    "Villa Domínico",
    "Villa Lañata",
    "Villa Libertad",
    "Villa Luzuriaga",
    "Villa Lynch",
    "Villa Maipú",
    "Villa Martelli",
    "Villa Rosa",
    "Villa Tesei",
    "Villa Zagala",
    "Virreyes",
    "Zelaya",
  ],
};

export const landingFaqData = {
  title: "Preguntas frecuentes",
  faqs: [
    {
      question: "¿Cómo me puedo hacer cliente?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Es muy fácil. Te comunicás al 0800-555-1900 nos hacés tu pedido y
          coordinamos un día y horario de visita.
        </p>
      ),
    },
    {
      question: "¿Con qué frecuencia me visita el repartidor?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Una vez coordinada la primera entrega el repartidor visitará su
          domicilio todas las semanas en el día y horario establecido para
          reponer los productos.
        </p>
      ),
    },
    {
      question: "¿Cómo puedo abonar?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Se puede abonar en efectivo o débito en cuenta.
        </p>
      ),
    },
    {
      question: "¿Puedo chequear mis consumos y hacer pedidos online?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Sí. Ingresa a MI CUENTA y con el mail que tenemos registrado en
          nuestra base de datos podes crear tu usuario.
        </p>
      ),
    },
    {
      question:
        "¿Si no estoy en el domicilio en el día acordado de la visita, tengo que esperar hasta la próxima semana?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          No. Te comunicás a nuestro 0800-555-1900 y re agendaremos la visita
          para el día más próximo posible.
        </p>
      ),
    },
    {
      question: "¿Qué consumo debo tener para obtener un dispenser?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Para mantener el dispenser natural se solicita un consumo mínimo de un
          botellón cada 15 días. Para los dispenser frio calor se requiere un
          consumo de un botellón por semana. En ambos casos los dispenser se
          entregan en forma de comodato.
        </p>
      ),
    },
    {
      question: "¿El dispenser frío-calor lo entregan sin cargo?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Sí. El equipo frío calor se lo brindamos en comodato y para poder
          mantenerlo debe realizar un consumo semanal de un botellón de 12lts o
          20lts.
        </p>
      ),
    },
    {
      question: "¿Qué medidas tienen los dispenser?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Equipo de frío calor de pie: 150cm x 40cm x 40cm (incluye el botellón
          de 12lts.) Equipo de frío calor de mesada: 80cm x 40cm x 40cm (incluye
          el botellón de 12lts.) Dispenser Natural: 60cm x 30cm x 30cm (Incluye
          botellón de 12lts.)
        </p>
      ),
    },
    {
      question: "¿A qué temperatura sale el agua caliente?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          El agua caliente alcanza alrededor de los 85°C -95°C. Una vez que
          llega a ese límite se apaga la luz roja, el sistema de calentamiento
          deja de funcionar y la maquina queda en estado de mantenimiento de
          temperatura. Si el agua caliente desciende a menos de 80°C, el sistema
          de calentamiento se activa nuevamente.
        </p>
      ),
    },
    {
      question:
        "¿Si el dispenser frío-calor sufre algún desperfecto debe abonarlo el cliente?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          No, el servicio técnico es sin cargo. Solamente tenes que comunicarte
          al 0800-555-1900 y solicitar la visita del servicio técnico. Te
          estarán visitando dentro de las 48hrs.
        </p>
      ),
    },
    {
      question: "¿Cómo se limpia el dispenser frío-calor?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Recomendamos realizar cada seis meses el saneado del equipo frio
          calor. Para ello deberá comunicarse al 0800-555-1900 y solicitar el
          servicio de saneado. La visita se coordina dentro de las 72 horas y se
          brinda sin cargo.
        </p>
      ),
    },
    {
      question:
        "¿Puedo solicitar los análisis fisico-químicos y bacteriológicos de los productos?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          Sí, descargalos haciendo click{" "}
          <a
            href="https://aguaivessrosmino.rosmino.com.ar/analisis/analisis.pdf"
            target="_blank"
            rel="noreferrer"
          >
            AQUÍ
          </a>
        </p>
      ),
    },
    {
      question: "¿Es IVESS (-SODIO) baja en sodio?",
      answer: (
        <p className="fs-14 text-grey text-regular mt-1">
          El código alimentario argentino, considera como 'baja en sodio' a las
          aguas minerales y mineralizadas que tengan valores iguales o menores a
          10 mg/l. IVESS (-sodio) tiene sólo 9mg por litro de sodio y suele
          estar recomendada para aquellas familias que necesitan reducir el
          consumo de sodio.
        </p>
      ),
    },
  ],
};
